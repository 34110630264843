
import React, { useState, useRef } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";

import { archiveStream, downloadStream, getStreamUrl, sendRequest } from '../../Commands'
import { Container, Button, Row, Col } from "reactstrap";

import { Player } from "./Player";
import moment from "moment";

export function Stream(props) {



    const date = useRef("");
    const form = useRef(false);
    const dateRequest = useRef(new Date());
    const time = useRef("");
    const duration = useRef(0);
    const stream = useRef({});
    const [user, setUser] = useState({});
    const [player, setPlayer] = useState();
    const [message, setMessage] = useState();

    //const picker = <DatePicker value={value} selected={startDate} onChange={(date) => onDatePick(date)} />// <DatePicker minDate={dateNow.getDate() - 2} onChange={onChange} value={value} />;

    //console.log("Stream " + props.thing)
    React.useEffect(() => {
        const info = async () => {
            var inf = await Auth.currentSession()

            setUser(inf.idToken.payload);



        };
        info();
    }, []);








    const onDatePick = (event) => {


        date.current = event.target.value;

    }
    const onTimePick = (event) => {

        time.current = event.target.value;

    }
    const onDurationPick = (event) => {

        duration.current = parseInt(event.target.value);

    }
    const startCustomStream = async () => {

        //  console.log("stream id " + streamId.current);

        const now = new Date().getTime();
        stream.current.id = "custom-stream_" + now;
        stream.current.timeStampStart = dateRequest.current;




        const id = stream.current.id;
        //need to add start camera button ?
        const req = {
            "operation": "START-CAMERA",
            "timeStampStart": dateRequest.current,
            "user": user.preferred_username,
            "id": id
        };
        //sendRequest(props.thing, req);


        setPlayer(<Player stream={stream.current} thing={props.thing} />);



    };

    const requestLiveStream = async () => {

        //create a new stream 

        const now = new Date().getTime();
        stream.current.id = "live-stream_" + now;
        stream.current.timeStampStart = 0;

        // console.log("stream id " + streamId.current);

        const id = stream.current.id;
        const req = {
            "operation": "START-CAMERA",
            "timeStampStart": 0,
            "user": user.preferred_username,
            "id": id
        };
        if (props.thing.app.status !== "ON") {
            sendRequest(props.thing, req);

        } else {
            if (props.thing.status === "ON") {
                setPlayer(<Player stream={stream.current} thing={props.thing} />);
            }
        }




    }

    const requestShareLiveStream = async (type) => {

        //create a new stream 


        if (props.thing.app.status !== "ON") {
            requestLiveStream();

        } else {
            const url = await getStreamUrl(props.thing, stream.current.timeStampStart, duration.current);
            if (url != null) {
                console.log("Share url =  " + url)


                const token = url.split('=')[1]
                const endpoint = url.split('.')[0]
                const openUrl='https://stream.nomadev.ca/' + endpoint.split('//')[1] + '/' + token
                if(type==='share'){

                navigator.clipboard.writeText(openUrl)
                }else{

                    window.open(openUrl,'_blank')
                }
            } else {
                window.alert("The clip was not found")
            }
        }




    }


    const requestDownloadStream = async () => {

        if (!form.current) {
            setMessage(downloadForm);
        } else {
            setMessage("");
        }

        form.current = !form.current;

    }
    const requestArchiveStream = async () => {

        if (!form.current) {
            setMessage(archiveForm);
        } else {
            setMessage("");
        }

        form.current = !form.current;

    }
    const requestShareStream = async () => {

        if (!form.current) {
            setMessage(shareForm);
        } else {
            setMessage("");
        }

        form.current = !form.current;

    }
    const stopStream = () => {


        stream.current.id = "";
        setPlayer("");

    }
    const stopCamera = () => {

        var id = stream.current.id;
        if (stream.current.id === "") {
            id = "stop-camera_" + Date.now();
        } else {
            stopStream();
        }

        const req = {
            "operation": "STOP-CAMERA",
            "user": user.preferred_username,
            "id": id
        };

        sendRequest(props.thing, req);


    }




    const onSumitStreamFormButtonClick = () => {
        if (time.current !== "" && date.current !== "") {

            var dateSplit = date.current.split("-");


            var timeSplit = time.current.split(":");
            const h = timeSplit[0];
            const min = timeSplit[1];

            dateRequest.current = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2], h, min, 0).getTime();
            const now = new Date().getTime();


            if (dateRequest.current < now) {


                startCustomStream()
            } else {
                alert("You need to enter date in the past")
            }

        } else {
            alert("You need to enter past date and time\nDuration must be greater than 0")
        }


    }
    const onSumitDownloadFormButtonClick = () => {
        if (time.current !== "" && date.current !== "") {

            var dateSplit = date.current.split("-");


            var timeSplit = time.current.split(":");
            const h = timeSplit[0];
            const min = timeSplit[1];

            dateRequest.current = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2], h, min, 0).getTime();
            const now = new Date().getTime();


            if (dateRequest.current < now) {

                setMessage("");
                const time = moment(dateRequest.current).format("YYYY-MM-DD-HH:mm:ss");
                var name = props.thing.group + '-' + props.thing.id + '-startat-' + time + '.mp4';
                downloadStream(props.thing, dateRequest.current, duration.current, name);
            } else {
                alert("You need to enter date in the past")
            }

        } else {
            alert("You need to enter past date and time\nDuration must be greater than 0")
        }



    }
    const requestShareRecordedStream = async () => {
        if (time.current !== "" && date.current !== "") {

            var dateSplit = date.current.split("-");


            var timeSplit = time.current.split(":");
            const h = timeSplit[0];
            const min = timeSplit[1];

            dateRequest.current = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2], h, min, 0).getTime();
            const now = new Date().getTime();


            if (dateRequest.current < now) {

                console.log("Share url =  " + duration.current)
                const url = await getStreamUrl(props.thing, dateRequest.current, duration.current);
                if (url != null) {
                    console.log("Share url =  " + url)


                    const token = url.split('=')[1]
                    const endpoint = url.split('.')[0]

                    navigator.clipboard.writeText('https://stream.nomadev.ca/' + endpoint.split('//')[1] + '/' + token)
                } else {
                    window.alert("The clip was not found")
                }



            } else {
                alert("You need to enter date in the past")
            }

        } else {
            alert("You need to enter past date and time\nDuration must be greater than 0")
        }



    }
    const onSumitArchiveFormButtonClick = () => {
        if (time.current !== "" && date.current !== "") {

            var dateSplit = date.current.split("-");


            var timeSplit = time.current.split(":");
            const h = timeSplit[0];
            const min = timeSplit[1];

            dateRequest.current = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2], h, min, 0).getTime();
            const now = new Date().getTime();


            if (dateRequest.current < now) {

                setMessage("");
                const time = moment(dateRequest.current).format("YYYY-MM-DD-HH:mm:ss");
                var name = props.thing.group + '-' + props.thing.id + '-startat-' + time + '.mp4';
                archiveStream(props.thing, dateRequest.current, duration.current, name);
            } else {
                alert("You need to enter date in the past")
            }

        } else {
            alert("You need to enter past date and time\nDuration must be greater than 0")
        }



    }

    const customStreamButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="LiveButton"
        onClick={
            onSumitStreamFormButtonClick
        }><h6>Start Stream</h6></Button>;
    const submitDownloadFormButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="LiveButton"
        onClick={
            onSumitDownloadFormButtonClick
        }><h6>Get Video</h6></Button>;

    const submitArchiveFormButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="LiveButton"
        onClick={
            onSumitArchiveFormButtonClick
        }><h6>Archive Video</h6></Button>;

    const submitShareFormButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="LiveButton"
        onClick={
            requestShareRecordedStream
        }><h6>Copy Url</h6></Button>;

    const streamForm = <div>


        <form >

            <input type={"date"} onChange={onDatePick} required />

            <input type={"time"} onChange={onTimePick} required />

        </form>

    </div>;


    const downloadForm = <div>


        <form >

            <label  > Length (minutes) </label>
            <input id="duration" defaultValue={"1"} min={"1"} type={"number"} onChange={onDurationPick} required />

        </form>
        {submitDownloadFormButton}
    </div>;

    const archiveForm = <div>


        <form >

            <label  > Length (minutes) </label>
            <input id="duration" defaultValue={"1"} min={"1"} type={"number"} onChange={onDurationPick} required />

        </form>
        {submitArchiveFormButton}
    </div>;

    const shareForm = <div>


        <form >

            <label  > Length (minutes) </label>
            <input id="duration" defaultValue={"1"} min={"1"} type={"number"} onChange={onDurationPick} required />

        </form>
        {submitShareFormButton}
    </div>;


    var liveButtonName = "Start Live";
    if (props.thing.app.status !== "ON") {
        liveButtonName = "Start Camera";
    }

    const startLiveButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="startLiveButton"
        onClick={() => {
            requestLiveStream()

        }}>{liveButtonName}</Button>;
    const shareButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="downloadButton"
        onClick={() => {

            requestShareStream()
        }}>Share Stream</Button>;
    const shareLiveButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="downloadButton"
        onClick={() => {

            requestShareLiveStream('share')
        }}>Share Live</Button>;
        const newTabLiveButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="downloadButton"
        onClick={() => {

            requestShareLiveStream('tab')
        }}>Live New Tab</Button>;

    const downloadButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="downloadButton"
        onClick={() => {

            requestDownloadStream()
        }}>Download</Button>;
    const archiveButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="archiveButton"
        onClick={() => {

            requestArchiveStream()
        }}>Archive</Button>;

    const stopLiveButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="stopLiveButton"
        onClick={() => {
            stopStream()
        }}>Stop Stream</Button>;

    const stopCameraButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="stopCameraButton"
        onClick={() => {
            stopCamera()
        }}>Stop Camera</Button>;




    return (
        <div>
            <h1>Stream</h1>
            <Container fluid>
                <Row >
                    <Col md={2}>{startLiveButton}</Col>
                    <Col md={2}>{newTabLiveButton}</Col>
                    <Col md={2}>{shareLiveButton}</Col>
                    <Col md={2}>{streamForm}</Col>
                    <Col md={2}>{customStreamButton}</Col>
                    <Col md={2}>{shareButton}</Col>
                    <Col md={2}>{downloadButton}</Col>
                    <Col md={2}>{archiveButton}</Col>
                    <Col md={2}>{stopLiveButton}</Col>
                    <Col md={2}>{stopCameraButton}</Col>



                </Row>



                {message}
                {player}



            </Container>


        </div >
    )
};

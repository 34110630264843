import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import Services from './Services';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

/* to check later
if ('serviceWorker' in navigator) {

  


  navigator.serviceWorker.register('./service-worker.js')
    .then(function (registration) {
      Services.log('info', 'Registration has succeeded. ' + JSON.stringify(registration), 'index.js');
    })
    .catch(function (e) {

  
      Services.log('error', 'Registration has failed. ' + e, 'index.js');
    });


}
*/
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
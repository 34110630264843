import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Storage, Auth } from "aws-amplify";
import { useParams } from "react-router-dom";
import AppNavbar from '../NavBars/AppNavbar';

const ViewLog = () => {



  const { log } = useParams();


  let [logText, setLog] = useState("");
  //const { item_id } = useParams();

  const getObject = async (key) => {

    const result = await Storage.get(key, {
      download: true
    });
    var msg = {};

    await result.Body.text().then(string => {
      msg = JSON.parse(string);

      setLog(msg.updateLog);

      // console.log("got log url  " + JSON.stringify(msg));

    }).catch((err) => {

      setLog("The file you are trying to view does not exist anymore");
      console.log(err)

    });

  }



  useEffect(() => {

    const downloadLog = async () => {


      var vals = log.split("_");
      var key = vals[0] + '/task-requests-logs/' + vals[1] + "_" + vals[2];
     // console.log("getting log  " + key);
      //public/nomadev/task-requests-logs/update-device_1677123388029.json
      //  get folder and key name somehow

      await Storage.list(vals[0] + '/task-requests-logs/') // for listing ALL files without prefix, pass '' instead
        .then(async (result) => {

          
         
          var found = false;
          result.forEach(element => {
            if (element.key === key) {
              getObject(key);
              found = true;
            }
          });
          if (!found) {
            setLog("The file you are trying to view does not exist anymore");
          }

        }
        )
        .catch((err) => console.log(err));
    }
    downloadLog();
  }, [log]);





  return (
    <div>
      {AppNavbar}
      <Container fluid>
        <h5>Update Log Result</h5>
        <p>
          <textarea
            name="log"
            defaultValue={logText}
            rows={20}
            cols={40}
            readOnly
          />

        </p>
      </Container>
    </div >

  );
}
export default <ViewLog />;
import React from "react";

import { useParams,useNavigate  } from "react-router-dom";
const About = () => {
    let navigate = useNavigate();
    const { user_id } = useParams();
    const goHome = () => {

        navigate("/" );
    };
    console.log('about ' + user_id);
    return (
        <div>
            <h1>About page here!</h1>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, modi! {user_id}
            </p>
            <button onClick={goHome}>Go to home page</button>
        </div>
    );
};

export default <About />;

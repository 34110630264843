import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from './firebase';
import logo from '../ressources/logo192.png';
import '../components/App.css';


const Notification = () => {
  const [data, setNotification] = useState({ title: '', message: '' });
  const notify = () => toast(<ToastDisplay/>);
  function ToastDisplay() {
    return (
      <div>
      
        <p><img src={logo} className="App-logo" alt="logo" />   <b>{data?.title}</b></p>
        <p>{data?.message}</p>
      </div>
    );
  };

  useEffect(() => {
    if (data?.title) {
      notify();
     
    }
  }, [data])

  requestForToken();

  onMessageListener()
    .then((payload) => {
      var message;
      var title;
      if (payload?.data?.message) {
        message = payload.data.message;
        title = payload.data.title;
      } else {
        message = payload.data.default;
        title = 'Nomadev Mail';
      }

      setNotification({ title: title, message: message,  });

    })
    .catch((err) => console.log('failed: ', err));

  return (
    <Toaster/>
  )
}

export default Notification

import { Storage, API, graphqlOperation, Auth, } from "aws-amplify";
import { deleteEvent } from './graphql/mutations';
import { KinesisVideoClient, GetDataEndpointCommand } from "@aws-sdk/client-kinesis-video";
import { KinesisVideoArchivedMedia, GetHLSStreamingSessionURLCommand, ReadableStream, HLSPlaybackMode, GetClipCommand, ClipFragmentSelectorType, FragmentSelectorType } from "@aws-sdk/client-kinesis-video-archived-media";
import { Alert } from "reactstrap";







export const sendRequest = async function (thing, request) {
    // stop camera...
    //  console.log("stop-streaming job ");
    var req = {};


    req["thing"] = thing;
    req["request"] = request;

    Storage.vault.put('task-requests/' + request.id + '_' + thing.id + '.json', req);

}

const deleteLog = async (log, group) => {
    var path = group + '/task-requests-logs/' + log


    var result = await Storage.remove(path);

    console.log("result " + JSON.stringify(result));
}


export const deleteEvents = async function (events) {


    events.forEach(event => {
        const eventVariables = {
            id: event.id,
        }
        //delete events
        if (event.log) {

            deleteLog(event.log, event.group);
        }

        API.graphql(graphqlOperation(deleteEvent, { input: eventVariables }));

    });


}
const deleteVideo = async (key) => {



    var result = await Storage.vault.remove(key);
    console.log("remove key  " + key);
    console.log("result " + JSON.stringify(result));
}


export const deleteSelectedVideos = async function (videos, prefix) {


    videos.forEach(video => {

        deleteVideo(prefix + video.name);


    });

}
const downloadVideo = async (key, name) => {


    const result = await Storage.vault.get(key, { download: true });
    downloadBlob(result.Body, name);

}

export const downloadSelectedVideos = async (videos, prefix) => {

    videos.forEach(video => {

        downloadVideo(prefix + video.name, video.name);


    });
}
function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}



export const updateSettings = async function (thing, request) {



    //  console.log("update settings ");
    var req = {};
    req["request"] = request;
    req["thing"] = thing;


    Storage.vault.put('settings-requests/' + request.id + '-' + thing.id + '.json', req);



}

export const getStreamUrl = async function (thing, timeStampStart, length) {
    var end = 0;
    var expires = 43200;
    if (length) {
        if (length > 0) {
            expires = length * 60
            end = timeStampStart + (length * 60 * 1000);
        }
    }
    var url = "";
    var streamName = thing.id;

    const credentials = await Auth.currentCredentials();
    var params = {
        credentials: credentials,
        region: "us-east-1"
    }

    console.log(" getting url " + timeStampStart + " end " + end)

    var urlparams = {

        Expires: expires,
        PlaybackMode: HLSPlaybackMode.LIVE,
        StreamName: streamName
    };

    if (timeStampStart > 0) {
        if (end > 0) {

            urlparams = {

                Expires: expires,
                PlaybackMode: HLSPlaybackMode.ON_DEMAND,
                StreamName: streamName,
                HLSFragmentSelector: {
                    FragmentSelectorType: FragmentSelectorType.PRODUCER_TIMESTAMP,
                    TimestampRange: {

                        StartTimestamp: new Date(timeStampStart),
                        EndTimestamp: new Date(end)
                    }
                },
            };

        } else {
            urlparams = {

                Expires: expires,
                PlaybackMode: HLSPlaybackMode.LIVE_REPLAY,
                StreamName: streamName,
                HLSFragmentSelector: {
                    FragmentSelectorType: FragmentSelectorType.PRODUCER_TIMESTAMP,
                    TimestampRange: {

                        StartTimestamp: new Date(timeStampStart)
                    }
                },
            };
        }

    }

    const kinesisVideo = new KinesisVideoClient(params);

    //console.log('Fetching data endpoint');

    params["APIName"] = "GET_HLS_STREAMING_SESSION_URL";
    params["StreamName"] = streamName;
    // console.log(params)
    const endPCommand = new GetDataEndpointCommand(params);


    await kinesisVideo
        .send(endPCommand)
        .then((data) => {
            // console.log(JSON.stringify(data))
            params["endpoint"] = data.DataEndpoint;
        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            // finally.
        });




    const kinesisVideoArchived = new KinesisVideoArchivedMedia(params);

    const urlCommand = new GetHLSStreamingSessionURLCommand(urlparams);

    await kinesisVideoArchived
        .send(urlCommand)
        .then((data) => {
            // console.log(data)
            url = data.HLSStreamingSessionURL;
        })

        .catch((error) => {

            console.log(error)
        })
        .finally(() => {
            return null;
        });


    // console.log("url " + JSON.stringify(url))
    return url;

}

export const archiveStream = async function (stream, timeStampStart, length, name) {

    const blob = await getClip(stream, timeStampStart, length);
    if (blob != null) {
        Storage.vault.put('archived/' + name, blob);
    } else {
        window.alert("The clip was not found")
    }
    //console.log(await Storage.get('testclip/clip.mp4')).toString();

}


export const downloadStream = async function (stream, timeStampStart, length, name) {


    const blob = await getClip(stream, timeStampStart, length);

    if (blob != null) {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
        URL.revokeObjectURL(link.href);
    } else {
        window.alert("The clip was not found")
    }

}

const getClip = async function (stream, timeStampStart, length) {


    var streamName = stream.id;
    const credentials = await Auth.currentCredentials();
    var params = {
        credentials: credentials,
        region: "us-east-1"
    }

    const timeStampEnd = timeStampStart + (length * 60 * 1000);


    //get endpoint first
    const kinesisVideo = new KinesisVideoClient(params);

    params["APIName"] = "GET_CLIP";
    params["StreamName"] = streamName;

    const endPCommand = new GetDataEndpointCommand(params);


    await kinesisVideo
        .send(endPCommand)
        .then((data) => {
            // console.log(JSON.stringify(data))
            params["endpoint"] = data.DataEndpoint;
        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            // finally.
        });



    const kinesisVideoArchived = new KinesisVideoArchivedMedia(params);

    const dnlparams = {


        StreamName: streamName,

        ClipFragmentSelector: {
            FragmentSelectorType: ClipFragmentSelectorType.PRODUCER_TIMESTAMP,
            TimestampRange: {

                StartTimestamp: new Date(timeStampStart),
                EndTimestamp: new Date(timeStampEnd)
            }
        },
    };

    const dnlCommand = new GetClipCommand(dnlparams);

    //getClip
    var blob;
    await kinesisVideoArchived
        .send(dnlCommand)
        .then(async (data) => {
            console.log(data)


            const byt = await data.Payload.transformToByteArray();

            blob = new Blob([byt], { type: 'video/mp4' });


        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            return null
        });

    return blob

}






import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Button, Row, Col, UncontrolledAccordion, AccordionItem, Accordion, AccordionHeader, AccordionBody } from "reactstrap";
import moment from "moment";
import { GridApi, RefSelector } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { API, Auth, graphqlOperation, DESC, Storage } from "aws-amplify";
import { onCreateEvent } from '../../graphql/subscriptions';
import { listEvents } from '../../graphql/queries';
import AppNavbar from '../NavBars/AppNavbar'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "ag-grid-community/dist/styles/ag-theme-material.css";

import { deleteSelectedVideos, downloadSelectedVideos } from '../../Commands';


const InitialColData = [
  // { field: 'select', checkboxSelection: true, headerCheckboxSelection: true, width: 40 },

  //{ field: 'name', resizable: true, checkboxSelection: true, headerCheckboxSelection: true },
  { field: 'name', resizable: true,headerCheckboxSelection: true },
  { field: 'size', text:'Size (Mb)',resizable: true },
  { field: 'archivedAt', resizable: true },

];





const Archived = () => {


  const [user, setUser] = useState({});
  const [colDefs, setColDefs] = useState(InitialColData);
  const gridApi = useRef();
  const rows = useRef()
  const rowsChecked = useRef()
  const size = { height: 300, width: 700 };

  const PAGE_SIZE = 20;
  let nextToken = undefined;
  let hasNextPage = true;





  React.useEffect(() => {
    const info = async () => {
      var inf = await Auth.currentSession()

      setUser(inf.idToken.payload);

      //  console.log("set user ");

    };
    info()
  }, []);














  //...
  const loadNextPage = async () => {
    if (hasNextPage) {
      let response = await Storage.vault.list('archived', {
        pageSize: PAGE_SIZE,
        nextToken: nextToken,
      });
      if (response.hasNextToken) {
        nextToken = response.nextToken;
      } else {
        nextToken = undefined;
        hasNextPage = false;
      }
      return response;
      // render list items from response.results
    }
  }

  const getList = async () => {

    let response = await Storage.vault.list('archived', {
      pageSize: 'All',

    });
    return response
  }

  const refreshGrid = async () => {

    var videos = [];
    var videoList = await getList();
    //var videoList = await loadNextPage();



    console.log(JSON.stringify(videoList));

    videoList.forEach(video => {


      const time = moment(video.lastModified).format("YYYY-MM-DD HH:mm:ss");
      var vSize = video.size / 1000000;
      var roundedString = vSize.toFixed(2);
      var rounded = Number(roundedString);

      const vid = {
        name: video.key.split("/")[1],
        archivedAt: time,
        size: rounded


      }
      videos.push(vid);

    });

    rows.current = videos;
    rows.current.sort(function (a, b) {
      return b.archivedAt.localeCompare(a.archivedAt);
    });

    gridApi.current.setRowData(videos);

  }

  const onGridReady = async function (params) {

    gridApi.current = params.api;
    refreshGrid();


  }


  const deleteVideoButton = <Button color="dark" style={{ width: 140, height: 30 }}
    className="deleteEventsButton"
    onClick={(params) => {
      if (gridApi.current.getSelectedRows().length > 0) {
        const confirmBox = window.confirm(
          "Do you really want to delete the selected videos permanently?"
        )
        if (confirmBox === true) {



          var selected = gridApi.current.getSelectedRows();
          console.log('Deleting selected rows ' + JSON.stringify(selected));


          deleteSelectedVideos(selected, 'archived/');

          selected.forEach(row => {
            const index = rows.current.indexOf(row);

            rows.current.splice(index, 1);

          });

          gridApi.current.setRowData(rows.current);
        }

      } else {
        window.alert(
          "You need to select at least 1 event to delete"
        )
      }

    }}>Delete Videos</Button>;

  const downloadVideoButton = <Button color="dark" style={{ width: 140, height: 30 }}
    className="downloadButton"
    onClick={(params) => {
      if (gridApi.current.getSelectedRows().length > 0) {
        const confirmBox = window.confirm(
          "Do you really want to download the selected video(s) ?"
        )
        if (confirmBox === true) {



          var selected = gridApi.current.getSelectedRows();
          console.log('download selected rows ' + JSON.stringify(selected));

          downloadSelectedVideos(selected, 'archived/');

        }

      } else {
        window.alert(
          "You need to select at least 1 video to download"
        )
      }

    }}>Download Videos</Button>;

    //not implemented yet
  const shareVideoButton = <Button color="dark" style={{ width: 140, height: 30 }}
    className="shareButton"
    onClick={(params) => {
      if (gridApi.current.getSelectedRows().length > 0) {
        const confirmBox = window.confirm(
          "Do you really want to share the selected videos"
        )
        if (confirmBox === true) {



          var selected = gridApi.current.getSelectedRows();
          console.log('Sharing selected rows ' + JSON.stringify(selected));          /*deleteEvents(selected);


          selected.forEach(row => {
              const index = rows.current.indexOf(row);

              rows.current.splice(index, 1);

          });



          gridApi.current.setRowData(rows.current);*/
        }

      } else {
        window.alert(
          "You need to select at least 1 video to share"
        )
      }

    }}>Share Videos</Button>;


  const refreshButton = <Button color="dark" style={{ width: 140, height: 30 }}
    className="deleteEventsButton"
    onClick={(params) => {

      refreshGrid()


    }}>Refresh</Button>;


  const rowClicked = (params) => {

    var val = "";
    val = params.value;


    console.log("Row clicked " + params.value);


    //window.open("view-log/" + props.thing.group + '_' + val, '_parent', 'noreferrer');
    // downloadLog(params.value);



    //send to view log to do...

  };// loop through each node when it is filtered out



  const rowSelected = (params) => {

    var val = "";
    val = params.value;
    //rowsChecked.current=gridApi.current.getSelectedRows();
    //gridApi.current.setSelectedRows(rowsChecked.current);
    //rowsChecked.current=gridApi.current.getSelectedRows();
    //console.log("Row clicked " + params.value);
    var selected = gridApi.current.getSelectedRows();
    console.log('selected rows ' + JSON.stringify(selected));

    //window.open("view-log/" + props.thing.group + '_' + val, '_parent', 'noreferrer');
    // downloadLog(params.value);



    //send to view log to do...

  };
  /*
    //const { item_id } = useParams();
  
    const getObject = async (key) => {
  
      const result = await Storage.get(key, {
        download: true
      });
      var msg = {};
  
      await result.Body.text().then(string => {
        msg = JSON.parse(string);
  
        setLog(msg.updateLog);
  
        // console.log("got log url  " + JSON.stringify(msg));
  
      }).catch((err) => {
  
        setLog("The file you are trying to view does not exist anymore");
        console.log(err)
  
      });
  
    }
  
  
  
    useEffect(() => {
  
      const downloadLog = async () => {
  
  
        var vals = log.split("_");
        var key = vals[0] + '/task-requests-logs/' + vals[1] + "_" + vals[2];
       // console.log("getting log  " + key);
        //public/nomadev/task-requests-logs/update-device_1677123388029.json
        //  get folder and key name somehow
  
        await Storage.list(vals[0] + '/task-requests-logs/') // for listing ALL files without prefix, pass '' instead
          .then(async (result) => {
  
            
           
            var found = false;
            result.forEach(element => {
              if (element.key === key) {
                getObject(key);
                found = true;
              }
            });
            if (!found) {
              setLog("The file you are trying to view does not exist anymore");
            }
  
          }
          )
          .catch((err) => console.log(err));
      }
      downloadLog();
    }, [log]);
  
  
  
  
  
    return (
      <div>
        {AppNavbar}
        <Container fluid>
          <h5>Update Log Result</h5>
          <p>
            <textarea
              name="log"
              defaultValue={logText}
              rows={20}
              cols={40}
              readOnly
            />
  
          </p>
        </Container>
      </div >
  
    );*/
/*
 {deleteVideoButton}
          {downloadVideoButton}
          {shareVideoButton}
          {refreshButton}
*/
  return (
    <div>
      {AppNavbar}
      <Container fluid>
        <div className="ag-theme-alpine" style={{ height: size.height, width: size.width }}>
          <h2>Videos</h2>
          {deleteVideoButton}
          {downloadVideoButton}         
          {refreshButton}
          <AgGridReact
            change
            rowMultiSelectWithClick={true}
            suppressCellSelection={true}
            rowSelection='multiple'
            defaultColDef={{ sortable: true, filter: true }}
            pagination={true}
            rowData={rows.current}
            onGridReady={onGridReady}
            columnDefs={colDefs}>
          </AgGridReact>
        </div>
      </Container>
    </div >

  );
}
export default <Archived />;

import { Navbar, NavbarBrand, Button } from 'reactstrap';

import { Auth } from 'aws-amplify';
import Services from '../../Services';
import React , { useState}from "react";
import { useNavigate, useParams  } from "react-router-dom";

const AppNavbar = () => {
   const version="V.Dev.0.1.1";
    let [user, setUser] = useState({});
    let navigate = useNavigate();
    const { item_id } = useParams();

    const [enteredName, setName] = useState('');
    const [enteredEmail, setEmail] = useState('');
    const [enteredPhone, setPhone] = useState('');


    React.useEffect(() => {
        const info = async () => {
            var inf = await Auth.currentSession()
          
           setUser( inf.idToken.payload);          
          console.log("set user "+JSON.stringify(inf.idToken.payload));
           
        };
        info()
      }, []);
      
      const goArchived = () => {

        navigate("/archived");
    };

    const goHome = () => {

        navigate("/");
    };
    const goAbout = () => {

        navigate("/about");
    };

    const toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    const goUser = () => {
        navigate("/show-user/");
    }
    const signOut = () => {

        Auth.signOut()
            .then(data => {
                Services.log('info', 'Signout: ' + data, 'AppNavBar.js-Auth.signOut');
                window.location.reload(false);
            }).catch(err => Services.log('error', 'Signout: ' + err, 'AppNavBar.js-Auth.signOut'));

        // this.props.history.push('/');

    };

    return (<Navbar color="dark" dark expand="md">
    
        <NavbarBrand ><Button color="dark" onClick={goUser}><h3>{user.name}</h3></Button></NavbarBrand>
        <NavbarBrand ><Button color="dark" onClick={goHome}>Home</Button></NavbarBrand>
        <NavbarBrand ><Button color="dark" onClick={goArchived}>Archived Video</Button></NavbarBrand>
        <NavbarBrand ><Button color="dark" onClick={goAbout}>About</Button></NavbarBrand>
        <NavbarBrand ><Button color="link" onClick={signOut}>Sign out</Button></NavbarBrand>
        <NavbarBrand color="dark" >{version}</NavbarBrand>
    </Navbar >);




};

export  default <AppNavbar />;

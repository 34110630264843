import {  Storage,API, Auth, graphqlOperation } from "aws-amplify";
import { onCreateEvent } from './graphql/subscriptions';
import { listEvents, listThings } from './graphql/queries';


let crypto = require('crypto-js');
let Services = function () { };
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

Services.downloadAttach = async function (folder, name) {

    //  get folder and key name somehow
    const result = await Storage.get('attachments/' + folder + '/' + name, { level: 'private', expires: 60 });

    return result.toString();


}



Services.getUsername = async function () {

    try {
        let info = await Auth.currentSession();
       
        return {
            'email': info.idToken.payload['email'],
            'username': info.idToken.payload['cognito:username'],
            'name': info.idToken.payload['name']
           
        }


    } catch (err) {

    }

}

Services.getMessages = async function () {

    var fileKeys;
    await Storage.list('mailbox') // for listing ALL files without prefix, pass '' instead
        .then(result => {
            fileKeys = result;

        }).catch();


    return await this.downloadFiles(fileKeys);



}



Services.downloadFiles = async function (fileKeys) {

    let files = [];
    for (var i = 0; i < fileKeys.length; i++) {
        var key = fileKeys[i].key;


        if (key.substring(0, 12) === 'mailbox/head') {


            const result = await this.downloadFile(key, i + 1);


            // eslint-disable-next-line no-loop-func

            var msg = result;

            files.push(msg);


        }

    }


    return files;
}

Services.log = async function (level, message, from) {


    var userName = await Services.getUsername();
    var date = Date.now();
    var path = 'logs/' + level + '-';
    var loguser = userName.username + '-';
    var uniqueId = path + loguser + crypto.MD5(date.toString() + '-' + loguser.toString()) + '-client-app-log.json';

    var strdate = new Date(date).toString().substring(0, 24);

    var logmsg = JSON.stringify({ "Date": strdate, "From": from, "Message": message }, null, 2);
    if (isLocalhost) {
        console.log(level, logmsg);
    } else {
        try{
        await Services.saveFile(uniqueId, logmsg, 'public');
        } catch(e){
            console.log("error saving log", e);
        }
    }
}
Services.downloadTmpFile = async function (fileKey) {


    const result = await Storage.get(fileKey, {
        download: true
    });

    var msg = '';
    // data.Body is a Blob
    // eslint-disable-next-line no-loop-func
    await result.Body.text().then(string => {
        msg = JSON.parse(string);


    });
    return msg;
}

Services.downloadObject = async function (fileKey, level) {


    const result = await Storage.get(fileKey, {
        download: true,
        level: level,
    });

    var msg = '';
    // data.Body is a Blob
    // eslint-disable-next-line no-loop-func
    await result.Body.text().then(string => {
        msg = JSON.parse(string);

    });
    return msg;
}

Services.downloadFile = async function (fileKey, index) {


    const result = await Storage.get(fileKey, {
        download: true
    });

    var msg = '';
    // data.Body is a Blob
    // eslint-disable-next-line no-loop-func
    await result.Body.text().then(string => {
        msg = JSON.parse(string);

        msg.id = index;
        msg.fileKey = fileKey;



    });
    return msg;
}
Services.saveFile = async function (filename, msgstr, level) {


    const results = await Storage.put(filename, msgstr, {

        level: level,

    });
    return results;





}


export default Services;



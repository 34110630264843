import React, { Component } from 'react';
import { render } from 'react-dom';
import './App.css';
import Home from './Pages/Home';

import { BrowserRouter, Routes, Route } from 'react-router-dom';


import UserForm from './Forms/UserForm';
import ThingForm from './Forms/ThingForm';
import ViewLog from './Display/ViewLog';
import Archived from './Pages/Archived';
import ThingSettings from './Forms/ThingSettings';
import About from "./Pages/About";
import { withAuthenticator } from '@aws-amplify/ui-react'
import {Amplify} from 'aws-amplify';

import aws_exports from '../aws-exports';
import '@aws-amplify/ui-react/styles.css';

import Notification from '../firebaseNotifications/Notification'





Amplify.configure(aws_exports);




function App() {

  return (
    <><div><Notification /></div><BrowserRouter>
      <Routes>
        <Route path='/' exact={true} element={Home} />
        <Route path='/show-user/' element={UserForm} />
        <Route path='/show-thing/:item_id' element={ThingForm} />
        <Route path='/settings/:item_id' element={ThingSettings} />
        <Route path='/view-log/:log' element={ViewLog} />
        <Route path='/archived/' element={Archived} />
        <Route path='/about' element={About} />

      </Routes>
    </BrowserRouter></>

  )
}


export default withAuthenticator(App);

import React,{ useState,useContext} from "react";
import { useNavigate } from "react-router-dom";
import '../App.css';
import { Button, Container, UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';

import { useParams } from "react-router-dom";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { EventsGrid } from "../Grids/EventsGrid";
import { ThingsGrid } from "../Grids/ThingsGrid";
import { Stream } from "./Stream";
import { Device } from "./Device";

import AppNavbar from '../NavBars/AppNavbar';

import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';


const DashBoard = () => {

    let navigate = useNavigate();
    const [expandedItem, setExpandedItem] = useState("0")



    const goDevice = () => {
        navigate("/show-thing/new");
    }


    return (
        <div>
            <Container fluid>
                <h1>Dashboard</h1>

                <div className="d-flex justify-content-left-0">

                    <Button color="dark" onClick={goDevice}>Add Device</Button>

                </div>


                <UncontrolledAccordion flush defaultOpen="liveStatus" stayOpen>



                    <AccordionItem  
                    
           
                    >
                        <AccordionHeader targetId="LiveStatus">
                            Devices Status
                        </AccordionHeader>
                        <AccordionBody accordionId="LiveStatus">



                            <Device />





                        </AccordionBody>

                    </AccordionItem>


                </UncontrolledAccordion>

            </Container>
        </div>
    );
};

export default <DashBoard />;



/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createThing = /* GraphQL */ `
  mutation CreateThing(
    $input: CreateThingInput!
    $condition: ModelThingConditionInput
  ) {
    createThing(input: $input, condition: $condition) {
      id
      group
      type
      status
      cpu
      changed
      syncDelay
      app {
        id
        group
        type
        status
        changed
        width
        camAuto
        motionDetect
        motionDetectArea
        fps
        delay
        bitrate
        color
        retention
        createdAt
        description
        updatedAt
        comments
      }
      createdAt
      description
      updatedAt
      comments
    }
  }
`;
export const updateThing = /* GraphQL */ `
  mutation UpdateThing(
    $input: UpdateThingInput!
    $condition: ModelThingConditionInput
  ) {
    updateThing(input: $input, condition: $condition) {
      id
      group
      type
      status
      cpu
      changed
      syncDelay
      app {
        id
        group
        type
        status
        changed
        width
        camAuto
        motionDetect
        motionDetectArea
        fps
        delay
        bitrate
        color
        retention
        createdAt
        description
        updatedAt
        comments
      }
      createdAt
      description
      updatedAt
      comments
    }
  }
`;
export const deleteThing = /* GraphQL */ `
  mutation DeleteThing(
    $input: DeleteThingInput!
    $condition: ModelThingConditionInput
  ) {
    deleteThing(input: $input, condition: $condition) {
      id
      group
      type
      status
      cpu
      changed
      syncDelay
      app {
        id
        group
        type
        status
        changed
        width
        camAuto
        motionDetect
        motionDetectArea
        fps
        delay
        bitrate
        color
        retention
        createdAt
        description
        updatedAt
        comments
      }
      createdAt
      description
      updatedAt
      comments
    }
  }
`;
export const createCamOperator = /* GraphQL */ `
  mutation CreateCamOperator(
    $input: CreateCamOperatorInput!
    $condition: ModelCamOperatorConditionInput
  ) {
    createCamOperator(input: $input, condition: $condition) {
      id
      group
      type
      status
      changed
      width
      camAuto
      motionDetect
      motionDetectArea
      fps
      delay
      bitrate
      color
      retention
      createdAt
      description
      updatedAt
      comments
    }
  }
`;
export const updateCamOperator = /* GraphQL */ `
  mutation UpdateCamOperator(
    $input: UpdateCamOperatorInput!
    $condition: ModelCamOperatorConditionInput
  ) {
    updateCamOperator(input: $input, condition: $condition) {
      id
      group
      type
      status
      changed
      width
      camAuto
      motionDetect
      motionDetectArea
      fps
      delay
      bitrate
      color
      retention
      createdAt
      description
      updatedAt
      comments
    }
  }
`;
export const deleteCamOperator = /* GraphQL */ `
  mutation DeleteCamOperator(
    $input: DeleteCamOperatorInput!
    $condition: ModelCamOperatorConditionInput
  ) {
    deleteCamOperator(input: $input, condition: $condition) {
      id
      group
      type
      status
      changed
      width
      camAuto
      motionDetect
      motionDetectArea
      fps
      delay
      bitrate
      color
      retention
      createdAt
      description
      updatedAt
      comments
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      user
      thing
      group
      createdBy
      name
      status
      log
      imageUploaded
      imageUrl
      stateUpdated
      error
      errorMessage
      createdAt
      updatedAt
      comments
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      user
      thing
      group
      createdBy
      name
      status
      log
      imageUploaded
      imageUrl
      stateUpdated
      error
      errorMessage
      createdAt
      updatedAt
      comments
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      user
      thing
      group
      createdBy
      name
      status
      log
      imageUploaded
      imageUrl
      stateUpdated
      error
      errorMessage
      createdAt
      updatedAt
      comments
    }
  }
`;

import React, { useState, useRef } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useParams, useNavigate } from "react-router-dom";
import AppNavbar from '../NavBars/AppNavbar';

const UserForm = () => {
  let navigate = useNavigate();
  //let user = useRef({});
  let [user, setUser] = useState({});
  //const { item_id } = useParams();




  React.useEffect(() => {
    const info = async () => {
      var inf = await Auth.currentSession()

      setUser(inf.idToken.payload);
    

    };
    info();
  }, []);







  const nameChangeHandler = (event) => {
   
    user.name = event.target.value;

    console.log("User name changed "+JSON.stringify(user.name));
    setUser(user);
   

  }


  const emailChangeHandler = (event) => {
    user.email = event.target.value;
    setUser(user);
    //  console.log("User name changed "+JSON.stringify(user));
   
  };
  const phoneChangeHandler = (event) => {
    console.log("User phone changed " + JSON.stringify(event.target));


    user.phone_number = event.target.value;
    setUser(user);
    console.log("User phone changed " + JSON.stringify(user.current));
  
  };





  const submitHandler = async (event) => {


    event.preventDefault();
    console.log("submit ", JSON.stringify(user.current))

    async function updateUser() {
      const userInf = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(userInf, {
        'name': user.name,
        'email': user.email,
        'phone_number': user.phone_number,
      });
    }
    await updateUser();

    alert('You Updated the Values: ' + user.name + ',' + user.phone_number + ',' + user.email);


    navigate("/");

  };







  return (
    <div>
      {AppNavbar}
      <Container fluid>
        <form onSubmit={submitHandler}>

          <label>User</label>
          <input type="text"  onChange={nameChangeHandler} placeholder={user.name} />

          <label>Email</label>
          <input type="email"  onChange={emailChangeHandler} placeholder={user.email}  />
          <label>Phone</label>
          <input type="text"  onChange={phoneChangeHandler} placeholder={user.phone_number} />





          <button type='submit'>Update User</button>
        </form>
      </Container>
    </div>

  );
}
export default <UserForm />;
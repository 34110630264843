import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useParams, useNavigate } from "react-router-dom";
import AppNavbar from '../NavBars/AppNavbar';
import { getThing, getUser } from '../../graphql/queries';
import { updateThing, createThing, deleteThing, createCamOperator, updateCamOperator } from '../../graphql/mutations';
import { updateSettings } from '../../Commands';

const ThingForm = () => {
  let navigate = useNavigate();

  const [user, setUser] = useState();
  const [thing, setThing] = useState({});

  const [thingId, setThingId] = useState("");
  const [thingGroup, setThingGroup] = useState("");
  const { item_id } = useParams();
  const [enteredType, setType] = useState('');
  const [enteredStatus, setStatus] = useState('');
  const [enteredDescription, setDescription] = useState('');
  const [enteredComments, setComments] = useState('');
  const [update, setUpdate] = useState();
  const [contentEditable, setContentEditable] = useState();
  const [submit, setSubmit] = useState();
  const [header, setHeader] = useState();




  React.useEffect(() => {
    const info = async () => {
      var inf = await Auth.currentSession()

      setUser(inf.idToken.payload);



    };
    info();
  }, []);





  React.useEffect(() => {
    const fetchThing = async () => {


      console.log("thing fetch " + item_id);
      var thing = await API.graphql({ query: getThing, variables: { id: item_id }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      // console.log("thing " + JSON.stringify(thingFetch));
      setThing(thing.data.getThing);
      setThingId(thing.data.getThing.id);


      //no null fields allowed
      if (thing.data.getThing.type) setType(thing.data.getThing.type);
      if (thing.data.getThing.status) setStatus(thing.data.getThing.status);
      if (thing.data.getThing.group) setThingGroup(thing.data.getThing.group);
      if (thing.data.getThing.description) setDescription(thing.data.getThing.description);
      if (thing.data.getThing.action) setDescription(thing.data.getThing.action);
      if (thing.data.getThing.comments) setComments(thing.data.getThing.comments);

      setUpdate(true);
      setContentEditable("false");
      setSubmit("Update device");
      setHeader("Update your device information");



    };




    if (item_id !== 'new') {
      fetchThing();
    } else {

      const newThing = {

        id: "",
        type: "",
        status: "",
        action: "",
        description: "",
        comments: "",
      }


      setThing(newThing);

      setUpdate(false);
      setContentEditable("true");
      setHeader("Enter your new device information");
      setSubmit("Add device");
    }
  }, [item_id]);



  const idChangeHandler = (event) => {
    thing.id = event.target.value;
    thing.app.id = event.target.value;
    thing.app.changed = true;
    thing.changed = true;
    setThing(thing);
    console.log("Thing id changed " + JSON.stringify(thing.id));
    setThingId(event.target.value);
  };
  const groupChangeHandler = (event) => {
    thing.group = event.target.value;
    thing.app.id = event.target.value;
    thing.app.changed = true;
    thing.changed = true
    setThing(thing);
    console.log("Thing group changed " + JSON.stringify(thing.group));
    setThingGroup(event.target.value);
  };
  const typeChangeHandler = (event) => {
    thing.type = event.target.value;
    thing.changed = true
    setThing(thing);
    console.log("Thing type changed " + JSON.stringify(thing.type));
    setType(event.target.value);
  };
  const statusChangeHandler = (event) => {
    thing.status = event.target.value;
    thing.changed = true
    setThing(thing);
    console.log("Thing status changed " + JSON.stringify(thing.status));
    setStatus(event.target.value);
  };
  const descriptionChangeHandler = (event) => {
    thing.description = event.target.value;
    thing.changed = true
    setThing(thing);
    console.log("Thing description changed " + JSON.stringify(thing.description));
    setDescription(event.target.value);
  };

  const commentsChangeHandler = (event) => {
    thing.comments = event.target.value;
    thing.changed = true
    setThing(thing);
    console.log("Thing comments changed " + JSON.stringify(thing.comments));
    setComments(event.target.value);
  };


  const submitHandler = async (event) => {
    event.preventDefault();



    if (update) {


      event.preventDefault();
      const confirmBox = window.confirm(
        "Do you really want to apply these settings to your device ?"
      )
      if (confirmBox === true) {

        const id = 'update_' + Date.now();
        const req = {
          "operation": "UPDATE-SETTINGS",
          "user": user.preferred_username,
          "id": id
        };

        updateSettings(thing, req);


        navigate("/");
      }

    } else {
      //first create new CamOperator
      const camOperatorVar = {

        id: thingId,
        group: thingGroup,
        type: enteredType,
        changed: true,
        status: enteredStatus,
        description: enteredDescription,
        comments: enteredComments,
      }
      //create new app
      console.log("creating new app " + JSON.stringify(camOperatorVar));
      await API.graphql(graphqlOperation(createCamOperator, { input: camOperatorVar }));

      const variables = {

        id: thingId,
        group: thingGroup,
        type: enteredType,
        changed: true,
        status: enteredStatus,
        description: enteredDescription,
        comments: enteredComments,
      }


      //create new thing
      console.log("creating new thing " + JSON.stringify(variables));
      await API.graphql(graphqlOperation(createThing, { input: variables }));



    }


    // return alert('Entered Values are: ' + enteredId + ',' + enteredName + ',' + enteredRole)



    navigate("/");

  };

  const deleteDevice = async (event) => {

    event.preventDefault();
    const confirmBox = window.confirm(
      "Do you really want to delete this device permanently?"
    )
    if (confirmBox === true) {

      // console.log("deleting device " + JSON.stringify(event));
      const variables = {

        id: thingId,
      }
      await API.graphql(graphqlOperation(deleteThing, { input: variables }));


      navigate("/");
    }

  };


  return (
    <div>
      {AppNavbar}
      <Container fluid>
        <h5>{header}</h5>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="form.id">
            <Form.Label>Id</Form.Label>
            <Form.Control type="text" value={thingId} onChange={idChangeHandler} contentEditable={contentEditable} placeholder="Enter device id" required />
          </Form.Group>
          <Form.Group controlId="form.group">
            <Form.Label>Group Name</Form.Label>
            <Form.Control type="text" value={thingGroup} onChange={groupChangeHandler} contentEditable={contentEditable} placeholder="Enter device group name" required />
          </Form.Group>
          <Form.Group controlId="form.Type">
            <Form.Label>Type</Form.Label>
            <Form.Control type="text" value={enteredType} onChange={typeChangeHandler} placeholder="Enter device type" required />
          </Form.Group>
          <Form.Group controlId="form.Status">
            <Form.Label>Status</Form.Label>
            <Form.Control type="text" value={enteredStatus} onChange={statusChangeHandler} placeholder="Enter device Status" required />
          </Form.Group>
          <Form.Group controlId="form.Description">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" value={enteredDescription} onChange={descriptionChangeHandler} placeholder="Enter device Description" required />
          </Form.Group>
          <Form.Group controlId="form.Comments">
            <Form.Label>Comments</Form.Label>
            <Form.Control type="text" value={enteredComments} onChange={commentsChangeHandler} placeholder="Enter device Comments" />
          </Form.Group>



          <Button type='submit'>{submit}</Button>


        </Form>
        <Button visible={false} color="dark" onClick={deleteDevice}>Delete Device</Button>

      </Container>
    </div>

  );
}
export default <ThingForm />;



/**
 * sample
 */



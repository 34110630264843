/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://nr773cpb55bwvmksssqil3yncq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wxjni4zyevhjtjat3waiev6lh4",
    "aws_cognito_identity_pool_id": "us-east-1:60756e04-9e8c-4354-a671-ad08d03bac81",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JFxCWT6Li",
    "aws_user_pools_web_client_id": "6hoqi2lgekej5rdu3lekitrgrq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PHONE_NUMBER",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "camwatchfrontend1c946f316da84580bcd08f86f1f6ae6100107-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

import React, { useState, useRef } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useParams, useNavigate } from "react-router-dom";
import AppNavbar from '../NavBars/AppNavbar';
import { getThing, getUser } from '../../graphql/queries';
import { updateThing, createThing, deleteThing } from '../../graphql/mutations';
import { updateSettings } from '../../Commands'

const ThingSettings = () => {
  let navigate = useNavigate();

  const [user, setUser] = useState();
  const thing = useRef({});
  const app = useRef({});

  const { item_id } = useParams();
  const [enteredWidth, setWidth] = useState();
  const [enteredCamAuto, setCamAuto] = useState(true);
  const [enteredMotionDetect, setMotionDetect] = useState(true);
  const [enteredFps, setFps] = useState();
  const [enteredBitrate, setBitrate] = useState();
  const [enteredSyncDelay, setSyncDelay] = useState();
  const [enteredMotionDetectArea, setMotionDetectArea] = useState();
  const [enteredDelay, setDelay] = useState(1);
  const [enteredColor, setColor] = useState(true);
  const [enteredRetention, setRetention] = useState(1);


  const [update, setUpdate] = useState();
  const [contentEditable, setContentEditable] = useState();
  const [submit, setSubmit] = useState();
  const [header, setHeader] = useState();


  React.useEffect(() => {
    const info = async () => {
      var inf = await Auth.currentSession()

      setUser(inf.idToken.payload);



    };
    info();
  }, []);




  React.useEffect(() => {



    const fetchThing = async () => {


      //  console.log("thing fetch " + item_id);
      var result = await API.graphql({ query: getThing, variables: { id: item_id }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      // //  console.log("thing " + JSON.stringify(thingFetch));
      ////  console.log("fetch result.data.getThing.motionDetect "+result.data.getThing.motionDetect);
      if (result.data.getThing.app !== null) {
        app.current = result.data.getThing.app;
      }


      //no null fields allowed
      if (app.current.width !== null) setWidth(app.current.width);
      if (app.current.camAuto !== null) setCamAuto(app.current.camAuto);
      if (app.current.motionDetect !== null) setMotionDetect(app.current.motionDetect);
      if (app.current.motionDetectArea !== null) setMotionDetectArea(app.current.motionDetectArea);
      if (app.current.fps !== null) setFps(app.current.fps);
      if (app.current.bitrate !== null) setBitrate(app.current.bitrate);
      if (app.current.delay !== null) setDelay(app.current.delay);
      if (result.data.getThing.syncDelay !== null) setSyncDelay(result.data.getThing.syncDelay);
      if (app.current.color !== null) setColor(app.current.color);
      if (app.current.retention !== null) setRetention(app.current.retention);
      thing.current = result.data.getThing;
      ////  console.log("fetch result.data.getThing.motionDetect "+enteredMotionDetect);
      setUpdate(true);
      setContentEditable("false");
      setSubmit("Update device");
      setHeader("Update your device settings");



    };




    fetchThing();


  }, [item_id]);




  const widthChangeHandler = (event) => {
    app.current.width = parseInt(event.target.value);
    app.current.changed = true;
    thing.current.app = app.current;
    ////  console.log("Camera width changed " + JSON.stringify(thing.current.width));
    setWidth(event.target.value);
  };
  const camautoChangeHandler = (event) => {
    app.current.camAuto = !app.current.camAuto;
    app.current.changed = true;
    thing.current.app = app.current;
    setCamAuto(app.current.camAuto);
  };
  const motionDetectChangeHandler = (event) => {

    app.current.motionDetect = !app.current.motionDetect;
    app.current.changed = true;
    thing.current.app = app.current;
    setMotionDetect(app.current.motionDetect);
  };
  const motionDetectAreaChangeHandler = (event) => {
    app.current.motionDetectArea = parseInt(event.target.value);
    app.current.changed = true;
    thing.current.app = app.current;
    //  console.log("motionDetectArea changed " + JSON.stringify(thing.current.motionDetectArea));
    setMotionDetectArea(event.target.value);
  };
  const fpsChangeHandler = (event) => {
    app.current.fps = parseInt(event.target.value);
    app.current.changed = true;
    thing.current.app = app.current;
    //  console.log("fps changed " + JSON.stringify(thing.current.fps));
    setFps(event.target.value);
  };

  const bitrateChangeHandler = (event) => {
    app.current.bitrate = parseInt(event.target.value);
    app.current.changed = true;
    thing.current.app = app.current;
    //  console.log("bitrate changed " + JSON.stringify(thing.current.bitrate));
    setBitrate(event.target.value);
  };
  const syncDelayChangeHandler = (event) => {
    thing.current.syncDelay = parseInt(event.target.value);
    thing.current.changed=true
    //  console.log("bitrate changed " + JSON.stringify(thing.current.bitrate));
    setSyncDelay(event.target.value);
  };

  const delayChangeHandler = (event) => {
    app.current.delay = parseInt(event.target.value);
    app.current.changed = true;
    thing.current.app = app.current;
    //  console.log("Delay changed " + JSON.stringify(thing.current.delay));
    setDelay(event.target.value);
  };

  const colorChangeHandler = (event) => {

    app.current.color = !app.current.color;
    app.current.changed = true;
    thing.current.app = app.current;
    setColor(app.current.color);
  };

  const retentionChangeHandler = (event) => {
    app.current.retention = parseInt(event.target.value);
    app.current.changed = true;
    thing.current.app = app.current;
    //  console.log("Retention changed " + JSON.stringify(thing.current.retention));
    setRetention(event.target.value);
  };









  const submitHandler = async (event) => {
    event.preventDefault();
    const confirmBox = window.confirm(
      "Do you really want to apply these settings to your device ?"
    )
    if (confirmBox === true) {

      const id = 'update_' + Date.now();
      const req = {
        "operation": "UPDATE-SETTINGS",
        "user": user.preferred_username,
        "id": id
      };






      updateSettings(thing.current, req);


      navigate("/");
    }
  };


  //  console.log("motion checked " + enteredMotionDetect);

  return (
    <div>
      {AppNavbar}
      <Container fluid width={200}>
        <h5>{header}</h5>
        <Form onSubmit={submitHandler} >
          <Form.Group controlId="form.id">
            <Form.Label>Id</Form.Label>
            <Form.Control type="text" value={item_id} contentEditable={false} required />
          </Form.Group>
          <Form.Group controlId="form.Width">
            <Form.Label>Width</Form.Label>
            <Form.Control type="number" min="320" max="1280" value={enteredWidth} onChange={widthChangeHandler} placeholder="Enter image width" required />
            <Form.Control type="range" name="width" min="320" max="1280" value={enteredWidth} onChange={widthChangeHandler} />
          </Form.Group>
          <Form.Group controlId="form.CamAuto">
            <Form.Label>Check for Auto Camera On</Form.Label>
            <Form.Check type="checkbox" checked={enteredCamAuto} onChange={camautoChangeHandler} placeholder="Enter camera auto on " />
          </Form.Group>
          <div>
            <Form.Group controlId="form.motionDetect">
              <Form.Label>Check for Motion Detect On</Form.Label>
              <Form.Check type="checkbox" checked={enteredMotionDetect} onChange={motionDetectChangeHandler} placeholder="Enter motion detect on " />
            </Form.Group>
          </div>
          <Form.Group controlId="form.motionDetectArea">
            <Form.Label>Motion Detect Area Size </Form.Label>
            <Form.Control type="number" min="500" max="100000" value={enteredMotionDetectArea} onChange={motionDetectAreaChangeHandler} placeholder="Enter motion detect area" required />
            <Form.Control type="range" name="area" min="500" max="100000" value={enteredMotionDetectArea} onChange={motionDetectAreaChangeHandler} />
          </Form.Group>
          <Form.Group controlId="form.Fps">
            <Form.Label>Fps </Form.Label>
            <Form.Control type="number" min="1" max="25" value={enteredFps} onChange={fpsChangeHandler} placeholder="Enter frames-per-seconds" required />
            <Form.Control type="range" name="Delay" min="1" max="25" value={enteredFps} onChange={fpsChangeHandler} />
          </Form.Group>
          <Form.Group controlId="form.Delay">
            <Form.Label>Delay (s)</Form.Label>
            <Form.Control type="number" min="2" max="30" value={enteredDelay} onChange={delayChangeHandler} placeholder="Enter delay in seconds" required />
            <Form.Control type="range" name="Delay" min="2" max="30" value={enteredDelay} onChange={delayChangeHandler} />
          </Form.Group>
          <Form.Group controlId="form.SyncDelay">
            <Form.Label>Sync rate ( s ) </Form.Label>
            <Form.Control type="number" min="5" max="600" value={enteredSyncDelay} onChange={syncDelayChangeHandler} placeholder="Enter sync rate in seconds" required />
            <Form.Control type="range" name="SyncDelay" min="5" max="600" value={enteredSyncDelay} onChange={syncDelayChangeHandler} />
          </Form.Group>
          <Form.Group controlId="form.Bitrate">
            <Form.Label>Max Bitrate (kbps)</Form.Label>
            <Form.Control type="number" min="50" max="2000" value={enteredBitrate} onChange={bitrateChangeHandler} placeholder="Enter bitrate in kbits per seconds" required />
            <Form.Control type="range" name="Bitrate" min="50" max="2000" value={enteredBitrate} onChange={bitrateChangeHandler} />
          </Form.Group>

          <Form.Group controlId="form.Color">
            <Form.Label>Check for Color Image</Form.Label>
            <Form.Check type="checkbox" checked={enteredColor} onChange={colorChangeHandler} placeholder="Enter color or grayscale" />
          </Form.Group>

          <Form.Group controlId="form.Retention">
            <Form.Label>Retention (hours)</Form.Label>
            <Form.Control type="number" min="0" max="87360" value={enteredRetention} onChange={retentionChangeHandler} placeholder="Enter retention in hours" required />
            <Form.Control type="range" name="Retention" min="0" max="87360" value={enteredRetention} onChange={retentionChangeHandler} />
          </Form.Group>





          <Button type='submit'>{submit}</Button>


        </Form>


      </Container>
    </div>

  );
}
export default <ThingSettings />;



/**
 * sample
 */



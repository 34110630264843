// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Services from '../Services'
import { API, graphqlOperation, Auth } from "aws-amplify";

//import { updateMessage, createUserTokens, updateUserTokens } from '../graphql/mutations';
//import * as queries from '../graphql/queries';


import '../components/App.css';
let user;

// In this sample we only handle the specific exceptions for the 'GetSecretValue' API.
// See https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
// We rethrow the exception by default.



const firebaseApp = initializeApp({
  apiKey: "AIzaSyCkYfk6v7botUiEjLNMiSu2_0CqrXKPfp4",

  authDomain: "mailsender-7e400.firebaseapp.com",

  projectId: "mailsender-7e400",

  storageBucket: "mailsender-7e400.appspot.com",

  messagingSenderId: "252991871018",

  appId: "1:252991871018:web:1b152039ce73d1223dcb53",

  measurementId: "G-WFR9KTZG1B"
});



// Retrieve firebase messaging

const messaging = getMessaging(firebaseApp);


export const requestForToken = async () => {


  const currentToken = await getToken(messaging, { vapidKey: 'BGm9TNNd0V-Lky9LgA8XFvdxEG9-nk5ApHlllyq8zO1p-WQmcrYT86P99t7gxFr2h8sXEY13qVtf_SAs6KN3LsQ' });
  if (currentToken) {


    let info = await Auth.currentSession();

    //var userReq = await API.graphql({ query: queries.getUser, variables: { id: info.idToken.payload['cognito:username'] }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    //user = userReq.data.getUser;
    //console.log('user id ' + JSON.stringify(user));

    //must update endpoint to sns topic


    /*
    
        if (deviceTokensReq.data.getUserTokens === null) {
    
          try {
            Services.downloadObject('user.init', 'private');
          } catch {
            //first connect
            var init = {
              "username": user.id
            };
    
            Services.saveFile('user.init', init, 'private');
            var platform = user.push.replace('new-message-', 'app/GCM/');
            var tokens = []
            tokens.push(currentToken);
            deviceTokens = {
    
              id: user.id,
              topic: user.push,
              platform: platform,
              tokens: [],
              endpoints: []
    
            }
    
           
    
    
          }
    
    
    
        } else {
          deviceTokens = deviceTokensReq.data.getUserTokens;
        }
  
   var deviceTokensReq = await API.graphql({ query: queries.getUserTokens, variables: { id: user.id }, authMode: 'AMAZON_COGNITO_USER_POOLS' });

    var token = {};

    if (deviceTokensReq.data.getUser.tokens !== null) {
      var deviceTokens = deviceTokensReq.data.getUser;
      console.log('device tokens '+JSON.stringify(deviceTokensReq));
      if (!deviceTokens.tokens.includes(currentToken)) {


        token = {
          "username": user.id,
          "token": currentToken
        };
        console.log('updated token ' + JSON.stringify(token));
        Services.saveFile('device.token', token, 'private');


      }

    } else {
      token = {
        "username": user.id,
        "token": currentToken
      };
      console.log('updated token ' + JSON.stringify(token));
      Services.saveFile('device.token', token, 'private');

    }
  
    */

  } else {
    // Show permission request UI
    Services.log('error', 'No registration token available. Request permission to generate one: ', 'firebase.js-requestForToken');


  }

};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, async (payload) => {

      //  console.log('notification payload ' + JSON.stringify(payload));
      // console.log('notification message id ' + JSON.stringify(payload.data.msgId));
      var msg = {};
      if (payload.data.msgType === 'EXTERNAL') {
        msg = {
          id: payload.data.msgId,
          status: 'RECEIVED_PROCESSED'
        };







        //   console.log('updated message' + JSON.stringify(msg));
        // resolve(payload);

        var split = window.location.href.toString().split(['/']);

        if (split[split.length - 1] === 'emails') {
          // window.location.reload(false);
        }

      }
     // var storeconvo = API.graphql(graphqlOperation(updateMessage, { input: msg }));
    });
  });




/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getThing = /* GraphQL */ `
  query GetThing($id: ID!) {
    getThing(id: $id) {
      id
      group
      type
      status
      cpu
      changed
      syncDelay
      app {
        id
        group
        type
        status
        changed
        width
        camAuto
        motionDetect
        motionDetectArea
        fps
        delay
        bitrate
        color
        retention
        createdAt
        description
        updatedAt
        comments
      }
      createdAt
      description
      updatedAt
      comments
    }
  }
`;
export const listThings = /* GraphQL */ `
  query ListThings(
    $filter: ModelThingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        type
        status
        cpu
        changed
        syncDelay
        app {
          id
          group
          type
          status
          changed
          width
          camAuto
          motionDetect
          motionDetectArea
          fps
          delay
          bitrate
          color
          retention
          createdAt
          description
          updatedAt
          comments
        }
        createdAt
        description
        updatedAt
        comments
      }
      nextToken
    }
  }
`;
export const getCamOperator = /* GraphQL */ `
  query GetCamOperator($id: ID!) {
    getCamOperator(id: $id) {
      id
      group
      type
      status
      changed
      width
      camAuto
      motionDetect
      motionDetectArea
      fps
      delay
      bitrate
      color
      retention
      createdAt
      description
      updatedAt
      comments
    }
  }
`;
export const listCamOperators = /* GraphQL */ `
  query ListCamOperators(
    $id: ID
    $filter: ModelCamOperatorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCamOperators(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        type
        status
        changed
        width
        camAuto
        motionDetect
        motionDetectArea
        fps
        delay
        bitrate
        color
        retention
        createdAt
        description
        updatedAt
        comments
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      user
      thing
      group
      createdBy
      name
      status
      log
      imageUploaded
      imageUrl
      stateUpdated
      error
      errorMessage
      createdAt
      updatedAt
      comments
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        thing
        group
        createdBy
        name
        status
        log
        imageUploaded
        imageUrl
        stateUpdated
        error
        errorMessage
        createdAt
        updatedAt
        comments
      }
      nextToken
    }
  }
`;

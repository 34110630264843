import React, { useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Container, Button, Row, Col, UncontrolledAccordion, AccordionItem, Accordion, AccordionHeader, AccordionBody } from "reactstrap";
import { API, Auth, graphqlOperation, DESC } from "aws-amplify";
import { getThing, listThings } from '../../graphql/queries';
import { updateThing, createEvent, updateCamOperator } from '../../graphql/mutations';
import { EventsGrid } from "../Grids/EventsGrid";
import { sendRequest } from '../../Commands';

import moment from "moment";

import { onUpdateThing } from '../../graphql/subscriptions';
import { Stream } from "./Stream";

export function Device() {


  let navigate = useNavigate();
  const [eventId, setEventId] = useState("");
  const heartBeat = useRef(null);
  const total = useRef(0);
  const user = useRef({});
  const [states, setStates] = useState([]);
  const [containers, setContainers] = useState([]);
  const size = { height: 200, width: 1000 };
  const [expandedItem, setExpandedItem] = useState("0")

  const [group, setGroup] = useState("");
  //  //  console.log('about ' + user_id);

  React.useEffect(() => {
    const info = async () => {
      var inf = await Auth.currentSession()

      user.current = inf.idToken.payload;



    };
    info()
  }, []);



  const StatusContainer = useCallback((user, thing) => {






    //  console.log('statu system status ' + thing.status);

    // const lightIcon = <img src={`./icons/color_icons_${thing.deviceLight}.png`} className='img-fluid rounded-circle' alt='' />;
    const stateIcon = <img style={{ width: 120, height: 120 }} src={`./icons/color_icons_${thing.status}.png`} className='img-fluid rounded-circle' alt='' />;

    const camIcon = <img style={{ width: 120, height: 120 }} src={`./icons/color_icons_${thing.app.status}.png`} className='img-fluid rounded-circle-' alt='' />;


    const rebootAppButton = <Button color="dark" style={{ width: 140, height: 30 }}
      className="reboot Button"
      onClick={() => {
        const confirmBox = window.confirm(
          "Do you really want to reboot CamWatch?"
        )
        if (confirmBox === true) {

          const id = "reboot-app_" + Date.now();
          const req = {
            "operation": "REBOOT-APP",
            "user": user.preferred_username,
            "id": id
          };

          sendRequest(thing, req);
        }
      }}>Reboot App</Button>;
    const rebootButton = <Button color="dark" style={{ width: 140, height: 30 }}
      className="reboot Button"
      onClick={() => {
        const confirmBox = window.confirm(
          "Do you really want to reboot the OS?"
        )
        if (confirmBox === true) {
          const id = "reboot-device_" + Date.now();
          const req = {
            "operation": "REBOOT-DEVICE",
            "user": user.preferred_username,
            "id": id
          };
          sendRequest(thing, req);
        }
      }}>Reboot OS</Button>;

    const updateButton = <Button color="dark" style={{ width: 140, height: 30 }}
      className="reboot Button"
      onClick={() => {
        const confirmBox = window.confirm(
          "Do you really want to update the OS?"
        )
        if (confirmBox === true) {
          const id = "update-device_" + Date.now();
          const req = {
            "operation": "UPDATE-DEVICE",
            "user": user.preferred_username,
            "id": id
          };
          sendRequest(thing, req);
        }
      }}>Update OS</Button>;

    const editButton = <Button color="dark" style={{ width: 140, height: 30 }}
      className="edit Button"
      onClick={() => {


        navigate("/show-thing/" + thing.id);

      }}>Edit Device</Button>;


    const settingsButton = <Button color="dark" style={{ width: 140, height: 30 }}
      className="setting Button"
      onClick={() => {


        navigate("/settings/" + thing.id);

      }}>Settings</Button>;


    const speedtestButton = <Button color="dark" style={{ width: 140, height: 30 }}
      className="speedtest Button"
      onClick={() => {

        const id = "speedtest_" + Date.now();
        const req = {
          "operation": "RUN-SPEEDTEST",
          "user": user.preferred_username,
          "id": id
        };
        sendRequest(thing, req);


      }}>Speed Test</Button>;
    const time = moment(thing.updatedAt).format("YYYY-MM-DD HH:mm:ss");


    const motion = () => {

      if (thing.app.motionDetect) {

        return 'On';
      }
      return 'Off';



    }

    return (

      <AccordionItem
      >
        <AccordionHeader targetId={thing.id}>
          {thing.id}
        </AccordionHeader>

        <AccordionBody accordionId={thing.id}
        >


          <div>
            <Container fluid>

              <Row>
                <Col>Device Status</Col>
                <Col>Camera Status</Col>
                <Col>Image Width</Col>
                <Col>Motion Detect</Col>
                <Col>Motion Detect Area</Col>
                <Col>Fps</Col>
                <Col>Cpu % (1 min avg)</Col>
                <Col>Max Bitrate (kbps)</Col>
                <Col>Delay (s)</Col>
                <Col>Retention (h)</Col>
                <Col>Updated At</Col>



              </Row>
              <Row>

                <Col>  <Row> {stateIcon}</Row><Row  >{thing.status}</Row></Col>
                <Col>  <Row> {camIcon}</Row><Row  >{thing.app.status}</Row></Col>
                <Col>{thing.app.width}</Col>
                <Col>{motion()}</Col>
                <Col>{thing.app.motionDetectArea}</Col>
                <Col>{thing.app.fps}</Col>
                <Col>{thing.cpu}</Col>
                <Col>{thing.app.bitrate}</Col>
                <Col>{thing.app.delay}</Col>
                <Col>{thing.app.retention}</Col>
                <Col>{time}</Col>

              </Row>
              <Row >
                <Col md={2}>{editButton}</Col>
                <Col md={2}>{settingsButton}</Col>
                <Col md={2}>{rebootAppButton}</Col>
                <Col md={2}>{rebootButton}</Col>
                <Col md={2}>{updateButton}</Col>
                <Col md={2}>{speedtestButton}</Col>

              </Row>
              <Row>

                <Stream thing={thing} />

              </Row>
              <Row>
                <EventsGrid thing={thing} />
              </Row>


            </Container>
          </div>
        </AccordionBody>

      </AccordionItem>


    )


  })







  const checkHeartBeat = (things) => {


    //console.log("checkking heart beat " + total.current + ' ' + Date.now());
    total.current += 1;

    things.forEach(thing => {
      //console.log("checking heart beat " + thing.id)

      const fetchThing = async () => {


        // console.log("get stream -----" + streamId.current);
        var thingFetch = await API.graphql({ query: getThing, variables: { id: thing.id }, authMode: 'AMAZON_COGNITO_USER_POOLS' });

        const currentStatus = thingFetch.data.getThing.status;
        const syncDelay = thingFetch.data.getThing.syncDelay;

        const timeDiff = new Date(thingFetch.data.getThing.updatedAt);
        var now = Date.now() / 1000;
        var time = now - (timeDiff.getTime() / 1000);
        const online = ((syncDelay * 1.2) > time);


        //console.log("checking heart beat " + thing.id + ' ' + time + ' ' + online );

        if ((!online && time > 300) || (!online && (currentStatus !== "OFF" || currentStatus === "ON" || currentStatus === "STANDBY"))) {
          const thingVar = {
            id: thing.id,
            status: 'OFFLINE'
          }

          API.graphql(graphqlOperation(updateCamOperator, { input: thingVar }));

          API.graphql(graphqlOperation(updateThing, { input: thingVar }));
          const event = {

            id: 'device-offline-' + now,
            group: thing.group,
            thing: thing.id,
            user: user.current.preferred_username,
            name: 'Device Offline',
            status: 'OFFLINE',
            createdBy: 'Heart Beat Checker',

          }
          API.graphql(graphqlOperation(createEvent, { input: event }));
        }

      };


      fetchThing();



    });

    //  });
  }



  React.useEffect(() => {
    const fetchStates = async () => {



      const variables = {
        limit: 100,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        sortKeyFields: ["createdAt"],
        sortDirection: DESC,

      }
      const thingsFetch = await API.graphql(graphqlOperation(listThings, variables))

      //  console.log("listThings " + JSON.stringify(thingsFetch.data));
      setGroup(thingsFetch.data.listThings.items[0].group);
      setStates(thingsFetch.data.listThings.items);

      var tmp = []
      thingsFetch.data.listThings.items.forEach(element => {


        console.log(" StatusContainer fetch " + JSON.stringify(element));
        tmp.push(StatusContainer(user.current, element));

      });




      // //  console.log(" states fetch ");

      setContainers(tmp);


      if (heartBeat.current == null) {
        heartBeat.current = setInterval(() => {

          checkHeartBeat(thingsFetch.data.listThings.items)
        }

          , 30000)

      }

    };
    fetchStates();


    return function cleanup() {
      console.log("cleanup called");
      clearInterval(heartBeat.current);

    }
  }, [heartBeat, user]);






  React.useEffect(() => {
    // subscriptionRef.current
    const subscription = API.graphql({ query: onUpdateThing, variables: { group: group }, authMode: 'AMAZON_COGNITO_USER_POOLS' }).subscribe({
      next: ({ provider, value }) => {

        var tmp = []

        states.forEach(row => {

          if (value.data.onUpdateThing.id !== row.id) {

            tmp.push(StatusContainer(user.current, row));
          } else {
            tmp.push(StatusContainer(user.current, value.data.onUpdateThing));
          }

        });

        setContainers(tmp);


      },
      error: error => console.warn(error)
    });

    return () => {
      //cleanup
      subscription.unsubscribe()
    }

  }, [user, states, group]);







  return (
    <div
      style={{
        alignItems: "left",
        display: "Block",
        width: size.width,
        height: size.height,
        // padding: 30
      }}
    >

      {containers}


    </div>
  )
};



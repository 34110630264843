import React, { useState, useRef } from "react";
import ReactPlayer from 'react-player';
import { API, Auth, graphqlOperation } from "aws-amplify";
import { onUpdateStream } from '../../graphql/subscriptions';
import { getStream } from '../../graphql/queries';

import { Row, Col } from "reactstrap";
import { deleteStream } from "../../graphql/mutations";
import { getStreamUrl } from "../../Commands";


export function Player(props) {

    const loading = <p><img width="200" height="200" src="./loading.gif" alt="Initiating stream..."></img></p>;
    const [playerMessage, setMessage] = useState(loading);
    const [user, setUser] = useState({});
    const [url, setUrl] = useState("");


    React.useEffect(() => {
        const info = async () => {
            var inf = await Auth.currentSession()

            setUser(inf.idToken.payload);


        };
        info();
    }, []);




    React.useEffect(() => {
        const fetchStream = async () => {


             console.log("get stream streamUrl-----" + JSON.stringify(props.stream));
            const streamUrl = await getStreamUrl(props.thing,props.stream.timeStampStart,0);
            setUrl(streamUrl);
            setMessage("")

        };


        fetchStream();


    }, [props]);





    return (
        <div>
            <Row>

                <Col> {playerMessage}</Col>


            </Row>
            <Row>



                <ReactPlayer
                    controls
                    playing={true}
                    url={url} />



            </Row>

        </div>

    )
};



import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Button, Row, Col, UncontrolledAccordion, AccordionItem, Accordion, AccordionHeader, AccordionBody } from "reactstrap";
import moment from "moment";
import { GridApi, RefSelector } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { API, Auth, graphqlOperation, DESC, Storage } from "aws-amplify";
import { onCreateEvent } from '../../graphql/subscriptions';
import { listEvents } from '../../graphql/queries';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "ag-grid-community/dist/styles/ag-theme-material.css";

import { deleteEvents } from '../../Commands';





const InitialColData = [
    // { field: 'select', checkboxSelection: true, headerCheckboxSelection: true, width: 40 },

    { field: 'name', resizable: true,headerCheckboxSelection: true },
    { field: 'status', resizable: true, width: 120 },
    { field: 'user', resizable: true },
    { field: 'log', resizable: true },
    { field: 'createdAt', resizable: true },
    { field: 'thing', resizable: true },
    { field: 'type', resizable: true },
    { field: 'description', resizable: true },
    { field: 'imageUploaded', resizable: true },
    { field: 'imageUrl', resizable: true },
    { field: 'error', resizable: true },
    { field: 'stateUpdated', resizable: true },
    { field: 'errorMessage', resizable: true },
    { field: 'comments', resizable: true },
    { field: 'id', resizable: true },

];


export function EventsGrid(props) {

    // console.log("******Props " + JSON.stringify(props.thing));

    const size = { height: 300, width: 700 };
    let navigate = useNavigate();
    const [nextToken, setNextToken] = useState(undefined)
    const [nextNextToken, setNextNextToken] = useState()

    const [previousTokens, setPreviousTokens] = useState([])


    const [user, setUser] = useState({});
    const [colDefs, setColDefs] = useState(InitialColData);
    let gridApi = useRef();
    let rows = useRef()



    React.useEffect(() => {
        const info = async () => {
            var inf = await Auth.currentSession()

            setUser(inf.idToken.payload);

            //  console.log("set user ");

        };
        info()
    }, []);






    const onGridReady = async function (params) {

        //let filter = {
        //    priority: {
        //       eq: 1 // filter priority = 1
        //    }
        // };

        gridApi.current = params.api;

        var nextEvent = "";

        var variables = {
            filter: { thing: { eq: props.thing.id } },
            limit: 100,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            sortKeyFields: ["createdAt"],
            sortDirection: DESC,
        }

        var result = await API.graphql(graphqlOperation(listEvents, variables));

        nextEvent = result.data.listEvents.nextToken;
        var data = result.data.listEvents.items;

        //get the rest if more than limit

        while (nextEvent) {
            variables = {
                nextToken: nextEvent
            }


            result = await API.graphql(graphqlOperation(listEvents, variables));
            var tmp = result.data.listEvents.items;
            nextEvent = result.data.listEvents.nextToken;

            if (tmp.length > 0) {

                data.push(...tmp);

            }

        }

        data.sort(function (a, b) {
            return b.createdAt.localeCompare(a.createdAt);
        });
        data.forEach(element => {
            const time = moment(element.createdAt).format("YYYY-MM-DD HH:mm:ss");

            element.createdAt = time;

        });



        //  console.log("list events " + JSON.stringify(data));
        rows.current = data;
        params.api.setRowData(rows.current);
    }

    //sbscription
    //on create

    React.useEffect(() => {

        //  console.log('subscrbing events ' + props.thing);

        // subscriptionRef.current
        const subscription = API.graphql({ query: onCreateEvent, variables: { thing: props.thing.id }, authMode: 'AMAZON_COGNITO_USER_POOLS' }).subscribe({
            next: ({ provider, value }) => {


                let newRow = value.data.onCreateEvent;
                //  console.log('new event created  ' + JSON.stringify(value));

                const time = moment(newRow.createdAt).format("YYYY-MM-DD HH:mm:ss");

                newRow.createdAt = time;
                rows.current.push(newRow)
                rows.current.sort(function (a, b) {
                    return b.createdAt.localeCompare(a.createdAt);
                });
                gridApi.current.setRowData(rows.current);



                // console.log('new event created  ' + JSON.stringify(newRow.id));

            },
            error: error => console.warn(error)

        });


        return () => {
            //cleanup
            subscription.unsubscribe()
        }

    }, [props, rows])

    ///for test request uplaod


    // Set the parameters



    const deleteEventsButton = <Button color="dark" style={{ width: 140, height: 30 }}
        className="deleteEventsButton"
        onClick={(params) => {
            if (gridApi.current.getSelectedRows().length > 0) {
                const confirmBox = window.confirm(
                    "Do you really want to delete the selected events permanently?"
                )
                if (confirmBox === true) {



                    var selected = gridApi.current.getSelectedRows();
                    console.log('Deleting selected rows');
                    deleteEvents(selected);


                    selected.forEach(row => {
                        const index = rows.current.indexOf(row);

                        rows.current.splice(index, 1);

                    });



                    gridApi.current.setRowData(rows.current);
                }

            } else {
                window.alert(
                    "You need to select at least 1 event to delete"
                )
            }

        }}>Delete Events</Button>;



    const rowClicked = (params) => {

        var val = "";
        val = params.value;
       // console.log("Log clicked " + params.value);
        if (val) {
            if (val.includes('update-device') || val.includes('speedtest')) {
                console.log("Log clicked " + params.value);

                navigate("view-log/" + props.thing.group + '_' + val);
                //window.open("view-log/" + props.thing.group + '_' + val, '_parent', 'noreferrer');
                // downloadLog(params.value);

            }
        }

        //send to view log to do...

    };





    ///

    return (

        <div className="ag-theme-alpine" style={{ height: size.height, width: size.width }}>
            <h2>Live Events</h2>
            {deleteEventsButton}
            <AgGridReact
                change
                rowMultiSelectWithClick={true}
                suppressCellSelection={true}
                suppressScrollOnNewData={true}                
                onCellDoubleClicked={rowClicked}
                rowSelection='multiple'
                defaultColDef={{ sortable: true, filter: true }}
                pagination={true}
                rowData={rows.current}
                onGridReady={onGridReady}
                columnDefs={colDefs}>
            </AgGridReact>
        </div>

    )
};


/**
 * sample
 */
/*



    const subsToEvents = async () => {


        var info = await Auth.currentSession();
        var id = info.idToken.payload['cognito:username'];

        const subscription = await API.graphql({ query: onCreateEvent, variables: { userId: id }, authMode: 'AMAZON_COGNITO_USER_POOLS' }).subscribe({
            next: ({ provider, value }) => {

               // console.log('on event create ' + JSON.stringify(value));
               
               

            },
            error: error => console.warn(error)

        });

        return () => subscription.unsubscribe();

    };

  

    //ondelete  
    React.useEffect(() => {
        const subscribeDelete = async () => {
            var info = await Auth.currentSession();
            var id = info.idToken.payload['cognito:username'];
    
            const subscription = await API.graphql({ query: onDeleteEvent, variables: { userId: id }, authMode: 'AMAZON_COGNITO_USER_POOLS' }).subscribe({
                next: ({ provider, value }) => {
    
                    //  console.log('on event create ' + JSON.stringify(value));
    
                    //this.gridApi.applyTransaction({add:[value.data.onCreateEvent]});
                    //  this.gridApi.setRowData(value.data.onCreateEvent);
                    //  this.GridApi.updateRowData({
                    //    add: value.data.onCreateEvent,
                    // YOU CAN PASS THE INDEX AT WHICH THE NEW ROW IS TO BE ADDED
                    //   });
    
    
                    // console.log('tmp data ' + JSON.stringify(tmp));
    
                    // addItems(value.data.onCreateEvent);
    
                    console.log("delete event "+JSON.stringify(value.data.onDeleteEvent));
                    fetch();
    
    
    
                },
                error: error => console.warn(error)
    
            });
    
            return () => subscription.unsubscribe();
    
        }
       
        subscribeDelete();

    })


*/
import AppNavbar from '../NavBars/AppNavbar';
import '../App.css';
import {Container } from 'reactstrap';
import React from "react";
import { useNavigate } from "react-router-dom";

import DashBoard from "../Display/Dashboard";


const Home = () => {
 

    return (
        <div>
            {AppNavbar}
            <Container fluid>
             
               
               {DashBoard}


            </Container>
        </div>
    );
};

export default <Home />;

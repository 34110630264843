/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateThing = /* GraphQL */ `
  subscription OnUpdateThing($group: String!) {
    onUpdateThing(group: $group) {
      id
      group
      type
      status
      cpu
      changed
      syncDelay
      app {
        id
        group
        type
        status
        changed
        width
        camAuto
        motionDetect
        motionDetectArea
        fps
        delay
        bitrate
        color
        retention
        createdAt
        description
        updatedAt
        comments
      }
      createdAt
      description
      updatedAt
      comments
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($thing: String!) {
    onCreateEvent(thing: $thing) {
      id
      user
      thing
      group
      createdBy
      name
      status
      log
      imageUploaded
      imageUrl
      stateUpdated
      error
      errorMessage
      createdAt
      updatedAt
      comments
    }
  }
`;

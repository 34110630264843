// This optional code is used to register a service worker.

import Services from './Services';


// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a pae, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register(config) {
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }
    /*
    window.addEventListener('push', function(e) {
      const dataObj = e.data.json()
      const {data} = dataObj
      const notificationOptions = {}
      // do some logic to fulfill the notificationOptions
      
      e.waitUntil(
        window.registration.showNotification('Title', notificationOptions)
      );
    });
*/
    console.log('registering service worker', JSON.stringify(config));
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          Services.log('info', 'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://cra.link/PWA', 'serviceWorkerRegistration.js-addEventListener');


        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });




    /**
     * Listen for incoming Push events
     */

    /*
        window.addEventListener('push', (event) => {
          var data = {};
          Services.log('info', '[Service Worker] Push Received.', 'serviceWorkerRegistration.js-addEventListener');
          Services.log('info', `[Service Worker] Push had this data: "${event.data.text()}"`, 'serviceWorkerRegistration.js-addEventListener');
    
    
    
          if (!(window.self.Notification && window.self.Notification.permission === 'granted'))
            return;
    
          if (event.data)
            data = event.data.json();
    
          // Customize the UI for the message box 
          var title = data.title || "Web Push Notification";
          var message = data.message || "New Push Notification Received";
          var icon = logo;
          var badge = logo512;
          var options = {
            body: message,
            icon: icon,
            badge: badge
          };
    
          event.waitUntil(window.self.registration.showNotification(title, options));
    
        });
    */
    /**
     * Handle a notification click
     */
    window.addEventListener('notificationclick', (event) => {

      let url = 'https://mail.nomadev.ca';
      // This looks to see if the current is already open and
      // focuses if it is

      event.notification.close();
      // eslint-disable-next-line no-undef
      event.waitUntil(clients.matchAll({
        includeUncontrolled: true,
        type: "window"
      }).then(function (clientList) {
        console.log('tabs from registration', +JSON.stringify(clientList));
        for (var i = 0; i < clientList.length; i++) {
          var client = clientList[i];
          console.log('client', +JSON.stringify(client.url));
          if (client.url === url || client.url === 'http://localhost:3000/')
            return client.focus();
        }
        // eslint-disable-next-line no-undef
        if (clients.openWindow)
          // eslint-disable-next-line no-undef
          return clients.openWindow(url);
      }));
      Services.log('info', '[Service Worker] Notification click: ' + event, 'serviceWorkerRegistration.js-registerValidSW');
    });


  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.

              Services.log('info', 'New content is available and will be used when all ' +
                'tabs for this page are closed. See https://cra.link/PWA.', 'serviceWorkerRegistration.js-registerValidSW');
              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.

              Services.log('info', 'Content is cached for offline use.', 'serviceWorkerRegistration.js-registerValidSW');
              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      Services.log('error', 'Error during service worker registration: ' + error, 'serviceWorkerRegistration.js-registerValidSW');

    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      Services.log('error', 'No internet connection found. App is running in offline mode. ', 'serviceWorkerRegistration.js-checkValidServiceWorker');

    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        Services.log('error', 'unregistring. ' + error.message, 'serviceWorkerRegistration.js-unregister');

      });
  }
}
